@import 'fonts';



$black : rgba(0,0,0,1);
$white : rgba(255,255,255,1);
$gold : rgb(218, 175, 113);
$middle-grey : rgba(80,80,80,1);
$light-grey : rgba(220,220,220,1);

$header-w : 80px;
$main-font : 'Montserrat', sans-serif;
$signature-font : 'Amostely Signature';
$linearicons-font : 'Linearicons-Free';

$icons-path : '/images/icons/';

$icon-map : (
  'magic-wand' : $icons-path+'magic-wand.svg' ,
  'certificate' : $icons-path+'certificate.svg' ,
);

@mixin icon($icon : null) {
    @if($icon){
        background-image : url(map-get($icon-map, $icon));
    }

    background-position: center center;
    background-size: contain;
    &:after{
      content: "";
      display: block;
      padding-bottom: 100%
    }
  }

  @mixin make-it-square(){
      &:before{
          content: "";
          display: block;
          padding-bottom: 100%;
      }
  }

  .icon-square{
          display: inline-block;
          width: 3em;

          &.certificate{
            @include icon(certificate);
          }
  }

  @mixin bgimage($position, $size, $attachment : unset, $color : transparent, $image : null){
    background-position: $position;
    background-size: $size;
    background-color: $color;
    background-attachment: $attachment;
    background-repeat: no-repeat;

    @if($image){
        background-image : url('../images/#{$image}');
    }
}

  @mixin bgcolor($color){
      background-color: $color;
  }

  %signature{
      font-family: $signature-font;
  }

  %links{
      color: inherit;
      text-decoration: none;

      &:hover{
          text-decoration: none;
          color: inherit;
      }
  }

*{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

a{
    @extend %links;
}

body{
    padding-left: $header-w;
    font-family: $main-font;


    #menu-overlay{

        &.active{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #040404cc;
            z-index: 2;
        }
    }

    footer{
        background-color: $black;
        color: $white;

        .contacts{
            background-color: $gold;
            color: $black;

            .lnr{
                color: $white;
                font-size: 1.5em;
                margin-right: .3em;
                vertical-align: text-bottom;
            }
        }

        .disclaimer{
            display: block;
            width: 90%;
            margin: auto;
            font-size: .6em;
            color: $middle-grey;
            margin-top: 1em;
        }
    }

    header{
        position: relative;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        color: $white;
        z-index: 999;

        .collapsed{
            background-color: $black;
            width: $header-w;
            position: relative;
            background-position: center center;
            background-size: 50%;
            background-repeat: no-repeat;
            background-image: url(../images/lettering.svg);

            .logotype{
                background-color: $gold;
                background-position: center center;
                background-size: contain;
                border: 5px solid $gold;
                background-repeat: no-repeat;
                background-image: url(../images/logotype.svg);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
        
                &:before{
                    content:"";
                    padding-bottom: 100%;
                    display: block;
                }
        
            }
        
            .menu-icon{
                border-top: 1px solid $middle-grey;
                background-position: center center;
                background-size: 35%;
                background-repeat: no-repeat;
                background-image: url(../images/bars.svg);
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                cursor: pointer;
                &:before{
                    content:"";
                    padding-bottom: 100%;
                    display: block;
                }
            }
        }
    

        nav{

            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            margin-left: $header-w;
            background-color: $black;
            transform: translateX(-100%);
            z-index: -1;
            opacity: 0;
            transition: all .2s ease-in-out;
            -webkit-transition: all .2s ease-in-out;
            -moz-transition: all .2s ease-in-out;

            &.active{
                transform: translateX(0%);
                opacity: 1;
                transition: all .2s ease-in-out;
                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
            }

            a{
                &:hover{
                    color: $gold
                }
            }

            > ul{
                list-style: none;
                padding: 0;
                white-space: nowrap;
                margin: 0;
                text-align: left;
                font-size: 1.7em;
                font-weight: 600;

                > li{
                    padding-bottom: .5em;
                }
            }

            .langs{
                position: absolute;
                bottom: 0;
                left: 0;

                > a{
                    text-transform: uppercase;
                    font-size: 1.3em;
                    padding: 10px;

                    &.active{
                        color: $gold;
                    }
                }
            }
        }

    
    }

}

.bg{

    &.light-grey{
        @include bgcolor($light-grey);
    }

    &.black{
        @include bgcolor($black);
        color: $white;
    }

    &.bg-1{
        background-image: url(../images/bg_1.svg);
        background-position: center right;
        background-size: contain;
    }
    
}

.text-with-icon{
    color: $middle-grey;
    h6{
        font-weight: 600;
        color: $black;
    }

    .icon{
        width: 3em;
        display: inline-block;
        margin-bottom: 1em;
        @include make-it-square();
        
    }
}

#hp-bottom-carousel{
    h1{
        @extend %signature;
        color:$gold;
        font-size: 6em;
        margin: 0 0 .7em 0;
    }

    p{
        text-align: justify;
    }

}

.squared-navigation{
    > span{
        border: 1px solid $middle-grey;
        width: 3em;
        cursor: pointer;
        position: relative;

        &:after{
            font-family: $linearicons-font;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
        }

        &.prev{
            &:after{
                content:"\e875";
            }
        }

        &.next{
            &:after{
                content:"\e876";
            }
        }

        &:first-child{
            border-right-color: transparent;
            margin-right: -1px;
        }

        &:before{
            content: "";
            display: block;
            padding-bottom: 100%;
        }

    }
}

.signature{
    @extend %signature;
    color: $gold;

    &.big{
        font-size: 6em;
    }
}

.hp_section_1{
    img{
        transform: translateY(5em);
    }
}

.rev_slider{

    .tp-bullets{
        left: 98% !important;
       .tp-bullet{
           background-color: transparentize($middle-grey, 0.4);
           height: .25em;
           width: 3em;
           border-radius: 1em;

           &.selected{
               background-color: $gold;
           }
       } 
    }

    .tp-caption{

        &.signature{
            color: $white;
            @extend %signature;
        }

        &.text{
            color: $white;

            span{
                color: $gold;
                font-weight: 600;
                text-shadow: 0px 0px 10px rgba(33,33,33,.7);
            }
        }

        &.section{
            color: $white;
            &:before{
                content: "";
                width: 2em;
                height: .1em;
                border-radius: 1em;
                background-color: $gold;
                display: inline-block;
                vertical-align: middle;
                margin-right: 1em;
            }
        }



            ul{
                color: $white;
                &.list{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    font-size: .4em;
    
                    > li {
                        display: inline-block;
    
                        &:first-child{
                            &:before{
                                content: "";
                                display: none;
                            }
                        }
    
                        &:before{
                            content: "";
                            display: inline-block;
                            width: .4em;
                            height: .4em;
                            background-color: $gold;
                            border-radius: 100%;
                            margin: 0 .5em;
                            transform: translateY(-0.1em);
                        }
                    }
                }
            }

    }
}

button{
    border-width: 2px;
    font-size: .9em;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all .2s ease-out;

    &:hover{
        transition: all .2s ease-in-out;
    }

    &.b-w{
        border-color: $white;
        background-color: $black;
        color: $white;

        &:hover{
            background-color: lighten($black, 10);
        }
    }

    &.w-b{
        border-color: $middle-grey;
        background-color: $white;
        color: $black;

        &:hover{
            background-color: darken($white, 10);
        }
    }

    &.b-g{
        border-color: $gold;
        background-color: $black;
        color: $gold;

        &:hover{
            background-color: lighten($black, 10);
        }
    }
}

.page-header{
     
    @include bgimage(center center,cover,fixed);

    color: $white;
    position: relative;

    &:before{
        content: "";
        display: inline;
        background-color:  transparentize($black, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    ul{
            font-family: $main-font;
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: .2em;
            font-weight: 100;

            > li {
                display: inline-block;

                &:first-child{
                    &:before{
                        content: "";
                        display: none;
                    }
                }

                &:before{
                    content: "";
                    display: inline-block;
                    width: .4em;
                    height: .4em;
                    background-color: $gold;
                    border-radius: 100%;
                    margin: 0 .5em;
                    transform: translateY(-0.1em);
                }
            }

    }

    h1{
        font-family: $signature-font;
        font-size: 5em;
    }

    small{
        font-size: .2em;
        text-transform: uppercase;
        font-family: $main-font;
        margin-bottom: .7em;

        &:before{
            content: "";
            height: 3px;
            border-radius: 2em;
            display: inline-block;
            background-color: $gold;
            width: 2em;
            vertical-align: middle;
            margin-right: .5em;
        }
    }
}

.gold-circle{
    @include bgimage(bottom right, contain, normal, $white, 'gold-circle-bg.svg');
}

.grey-shot{
    @include bgimage(top left, contain, normal, $black, 'grey-shot-bg.svg');
    color: $white;

    .title{
        &.signature{
            font-size: 5em;
            color: $white;
        }
        &.classic{
            font-weight: 100;
            strong{
                color: $gold;
                font-weight: 700
            }
        }
    }
}
.grey-shot-light{
    @include bgimage(top left, contain, fixed, $white, 'grey-shot-bg.svg');
}

.grey-circle{
    @include bgimage(bottom right, contain, normal, $black, 'grey-circle-bg.svg');
    color: $white;

    .title{
        &.signature{
            font-size: 5em;
            color: $gold;
        }
        &.classic{
            font-weight: 100;
            strong{
                color: $gold;
                font-weight: 700
            }
        }
    }
}

.title{
    &.signature{
        font-size: 5em;
        color: $white;
    }
    &.classic{
        
        font-weight: 100;
        strong{
            color: $gold;
            font-weight: 700
        }
    }
}

ul{
    &.lined{
        list-style: none;
        padding: 0;
        margin: 0;
        padding-left: 2.5em;

        > li{
            margin-bottom: .5em;
            text-align: justify;
            &:before{
                content: "";
                display: inline-block;
                width: 2em;
                height: 2px;
                border-radius: 2em;
                background-color: $gold;
                vertical-align: middle;
                margin-left: -2.7em;
                margin-right: .7em;
            }
        }
    }
}

.squared{
    @include make-it-square;
}


form{

    text-align: left;

    label{
        text-transform: uppercase;
        font-size: .7em;
        display: block;
        color: $gold
    }

    .thx{
        font-size: .9em;
    }

    .error{
        color: rgba(255,0,0,1);
        text-transform: uppercase;
        font-size: .8em;
    }

    input:not([type='checkbox']), textarea {
        background-color: transparent;
        border: 0;
        display: block;
        width: 100%;
        margin-bottom: 2em;
        resize: none;
        font-size: .9em;
        padding: 5px;
        border-bottom: 1px solid $black;
        color: $black;

        &:focus{
            outline: none;
        }

    }

    button{
        &[type=submit]{
            background-color: $gold;
            border-color: $black;
            color: $black;
            &:focus{
                outline: none;
            }
            &[disabled]{
                opacity: .4;
            }
        }
    }

    .checkbox-label{
        @include make-it-square;
        background-color:transparent;
        display: inline-block;
        width: 1em;
        position: relative;
        cursor: pointer;
    

        > input[type=checkbox]{
            display: none;
        }

        > label{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background:transparent;
            margin: 0;
            border: 2px solid $gold;
            cursor: pointer;

            &.active{
                @include bgimage(center center, 60%, unset , transparent, 'icons/checkbox-check.svg');
            }
        }
    }

}


.big-modal{
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.bg-blk{
        background-color: $black;

        .modal-content{
            background-color: transparent;
            color: $white;
        }

        form{
            label{
                color: $gold;
            }

            button{
                &[type=submit]{
                    background-color: $black;
                    border-color: $gold;
                    color: $gold
                }
            }

            .thx{
                > span{
                    color: $gold;
                }
            }

            input:not([type='checkbox']), textarea {

                border-bottom: 1px solid $white;
                color: $white;

            }
        }

    }


    .popup-close{
        width: 4em;
        @include make-it-square;
        @include bgimage(center center, contain, unset , transparent, 'icons/close.svg');
        position: fixed;
        top: 2em;
        right: 2em;
        border: none;
    }

}

.image-carousel{
    &.square-img{
        .item{
            @include make-it-square();
            @include bgimage(center center, cover, unset);
        }
    }
}

.gmap{
    background-color: $light-grey;
    height: 25em;
    position: relative;
}

.reference{
    @include bgimage(center center, contain, unset);
}

.fade-enter {
    opacity: 0.01;
    transform: translateY(20px);
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: all 500ms ease-in;
  }
  
  .fade-leave {
    opacity: 1;
  }
  
  .fade-leave.fade-leave-active {
    opacity: 0.01;
    transform: translateY(20px);
    transition: all 300ms ease-in;
  }

  .fade-appear {
    opacity: 0.01;
  }
  
  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity .5s ease-in;
  }

  .filter-bar{
      button{
          margin: 0 .3em;
          &:focus{
              outline: none;
              background-color: $gold;
              color: $black;
          }
      }
  }

  .static-image{
      position: relative;

      &.hover{

        &:hover{
            > div{
                opacity: 1;
                transition: all .2s linear;
                -webkit-transition: all .2s linear;
                -moz-transition: all .2s linear;
            }
        }

          > div{
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          opacity: 0;
          transition: all .2s linear;
          -webkit-transition: all .2s linear;
          -moz-transition: all .2s linear;
        }
      }
  }