@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap&subset=latin-ext");
@font-face {
  font-family: "Amostely Signature";
  @font-face {
    font-family: "Amostely Signature";
    src: url("./fonts/AmostelySignature.eot?#iefix") format("embedded-opentype"); /* IE6-8 */
    src: url("./fonts/AmostelySignature.woff") format("woff"); /* Modern Browsers */
    src: url("./fonts/AmostelySignature.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Linearicons-Free";
  src: url("./fonts/Linearicons-Free.eot?w118d");
  src: url("./fonts/Linearicons-Free.eot?#iefixw118d") format("embedded-opentype"), url("./fonts/Linearicons-Free.woff2?w118d") format("woff2"), url("./fonts/Linearicons-Free.woff?w118d") format("woff"), url("./fonts/Linearicons-Free.ttf?w118d") format("truetype"), url("./fonts/Linearicons-Free.svg?w118d#Linearicons-Free") format("svg");
  font-weight: normal;
  font-style: normal;
}
.lnr {
  font-family: "Linearicons-Free";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lnr-home:before {
  content: "";
}

.lnr-apartment:before {
  content: "";
}

.lnr-pencil:before {
  content: "";
}

.lnr-magic-wand:before {
  content: "";
}

.lnr-drop:before {
  content: "";
}

.lnr-lighter:before {
  content: "";
}

.lnr-poop:before {
  content: "";
}

.lnr-sun:before {
  content: "";
}

.lnr-moon:before {
  content: "";
}

.lnr-cloud:before {
  content: "";
}

.lnr-cloud-upload:before {
  content: "";
}

.lnr-cloud-download:before {
  content: "";
}

.lnr-cloud-sync:before {
  content: "";
}

.lnr-cloud-check:before {
  content: "";
}

.lnr-database:before {
  content: "";
}

.lnr-lock:before {
  content: "";
}

.lnr-cog:before {
  content: "";
}

.lnr-trash:before {
  content: "";
}

.lnr-dice:before {
  content: "";
}

.lnr-heart:before {
  content: "";
}

.lnr-star:before {
  content: "";
}

.lnr-star-half:before {
  content: "";
}

.lnr-star-empty:before {
  content: "";
}

.lnr-flag:before {
  content: "";
}

.lnr-envelope:before {
  content: "";
}

.lnr-paperclip:before {
  content: "";
}

.lnr-inbox:before {
  content: "";
}

.lnr-eye:before {
  content: "";
}

.lnr-printer:before {
  content: "";
}

.lnr-file-empty:before {
  content: "";
}

.lnr-file-add:before {
  content: "";
}

.lnr-enter:before {
  content: "";
}

.lnr-exit:before {
  content: "";
}

.lnr-graduation-hat:before {
  content: "";
}

.lnr-license:before {
  content: "";
}

.lnr-music-note:before {
  content: "";
}

.lnr-film-play:before {
  content: "";
}

.lnr-camera-video:before {
  content: "";
}

.lnr-camera:before {
  content: "";
}

.lnr-picture:before {
  content: "";
}

.lnr-book:before {
  content: "";
}

.lnr-bookmark:before {
  content: "";
}

.lnr-user:before {
  content: "";
}

.lnr-users:before {
  content: "";
}

.lnr-shirt:before {
  content: "";
}

.lnr-store:before {
  content: "";
}

.lnr-cart:before {
  content: "";
}

.lnr-tag:before {
  content: "";
}

.lnr-phone-handset:before {
  content: "";
}

.lnr-phone:before {
  content: "";
}

.lnr-pushpin:before {
  content: "";
}

.lnr-map-marker:before {
  content: "";
}

.lnr-map:before {
  content: "";
}

.lnr-location:before {
  content: "";
}

.lnr-calendar-full:before {
  content: "";
}

.lnr-keyboard:before {
  content: "";
}

.lnr-spell-check:before {
  content: "";
}

.lnr-screen:before {
  content: "";
}

.lnr-smartphone:before {
  content: "";
}

.lnr-tablet:before {
  content: "";
}

.lnr-laptop:before {
  content: "";
}

.lnr-laptop-phone:before {
  content: "";
}

.lnr-power-switch:before {
  content: "";
}

.lnr-bubble:before {
  content: "";
}

.lnr-heart-pulse:before {
  content: "";
}

.lnr-construction:before {
  content: "";
}

.lnr-pie-chart:before {
  content: "";
}

.lnr-chart-bars:before {
  content: "";
}

.lnr-gift:before {
  content: "";
}

.lnr-diamond:before {
  content: "";
}

.lnr-linearicons:before {
  content: "";
}

.lnr-dinner:before {
  content: "";
}

.lnr-coffee-cup:before {
  content: "";
}

.lnr-leaf:before {
  content: "";
}

.lnr-paw:before {
  content: "";
}

.lnr-rocket:before {
  content: "";
}

.lnr-briefcase:before {
  content: "";
}

.lnr-bus:before {
  content: "";
}

.lnr-car:before {
  content: "";
}

.lnr-train:before {
  content: "";
}

.lnr-bicycle:before {
  content: "";
}

.lnr-wheelchair:before {
  content: "";
}

.lnr-select:before {
  content: "";
}

.lnr-earth:before {
  content: "";
}

.lnr-smile:before {
  content: "";
}

.lnr-sad:before {
  content: "";
}

.lnr-neutral:before {
  content: "";
}

.lnr-mustache:before {
  content: "";
}

.lnr-alarm:before {
  content: "";
}

.lnr-bullhorn:before {
  content: "";
}

.lnr-volume-high:before {
  content: "";
}

.lnr-volume-medium:before {
  content: "";
}

.lnr-volume-low:before {
  content: "";
}

.lnr-volume:before {
  content: "";
}

.lnr-mic:before {
  content: "";
}

.lnr-hourglass:before {
  content: "";
}

.lnr-undo:before {
  content: "";
}

.lnr-redo:before {
  content: "";
}

.lnr-sync:before {
  content: "";
}

.lnr-history:before {
  content: "";
}

.lnr-clock:before {
  content: "";
}

.lnr-download:before {
  content: "";
}

.lnr-upload:before {
  content: "";
}

.lnr-enter-down:before {
  content: "";
}

.lnr-exit-up:before {
  content: "";
}

.lnr-bug:before {
  content: "";
}

.lnr-code:before {
  content: "";
}

.lnr-link:before {
  content: "";
}

.lnr-unlink:before {
  content: "";
}

.lnr-thumbs-up:before {
  content: "";
}

.lnr-thumbs-down:before {
  content: "";
}

.lnr-magnifier:before {
  content: "";
}

.lnr-cross:before {
  content: "";
}

.lnr-menu:before {
  content: "";
}

.lnr-list:before {
  content: "";
}

.lnr-chevron-up:before {
  content: "";
}

.lnr-chevron-down:before {
  content: "";
}

.lnr-chevron-left:before {
  content: "";
}

.lnr-chevron-right:before {
  content: "";
}

.lnr-arrow-up:before {
  content: "";
}

.lnr-arrow-down:before {
  content: "";
}

.lnr-arrow-left:before {
  content: "";
}

.lnr-arrow-right:before {
  content: "";
}

.lnr-move:before {
  content: "";
}

.lnr-warning:before {
  content: "";
}

.lnr-question-circle:before {
  content: "";
}

.lnr-menu-circle:before {
  content: "";
}

.lnr-checkmark-circle:before {
  content: "";
}

.lnr-cross-circle:before {
  content: "";
}

.lnr-plus-circle:before {
  content: "";
}

.lnr-circle-minus:before {
  content: "";
}

.lnr-arrow-up-circle:before {
  content: "";
}

.lnr-arrow-down-circle:before {
  content: "";
}

.lnr-arrow-left-circle:before {
  content: "";
}

.lnr-arrow-right-circle:before {
  content: "";
}

.lnr-chevron-up-circle:before {
  content: "";
}

.lnr-chevron-down-circle:before {
  content: "";
}

.lnr-chevron-left-circle:before {
  content: "";
}

.lnr-chevron-right-circle:before {
  content: "";
}

.lnr-crop:before {
  content: "";
}

.lnr-frame-expand:before {
  content: "";
}

.lnr-frame-contract:before {
  content: "";
}

.lnr-layers:before {
  content: "";
}

.lnr-funnel:before {
  content: "";
}

.lnr-text-format:before {
  content: "";
}

.lnr-text-format-remove:before {
  content: "";
}

.lnr-text-size:before {
  content: "";
}

.lnr-bold:before {
  content: "";
}

.lnr-italic:before {
  content: "";
}

.lnr-underline:before {
  content: "";
}

.lnr-strikethrough:before {
  content: "";
}

.lnr-highlight:before {
  content: "";
}

.lnr-text-align-left:before {
  content: "";
}

.lnr-text-align-center:before {
  content: "";
}

.lnr-text-align-right:before {
  content: "";
}

.lnr-text-align-justify:before {
  content: "";
}

.lnr-line-spacing:before {
  content: "";
}

.lnr-indent-increase:before {
  content: "";
}

.lnr-indent-decrease:before {
  content: "";
}

.lnr-pilcrow:before {
  content: "";
}

.lnr-direction-ltr:before {
  content: "";
}

.lnr-direction-rtl:before {
  content: "";
}

.lnr-page-break:before {
  content: "";
}

.lnr-sort-alpha-asc:before {
  content: "";
}

.lnr-sort-amount-asc:before {
  content: "";
}

.lnr-hand:before {
  content: "";
}

.lnr-pointer-up:before {
  content: "";
}

.lnr-pointer-right:before {
  content: "";
}

.lnr-pointer-down:before {
  content: "";
}

.lnr-pointer-left:before {
  content: "";
}

.icon-square {
  display: inline-block;
  width: 3em;
}
.icon-square.certificate {
  background-image: url(../images/certificate.svg);
  background-position: center center;
  background-size: contain;
}
.icon-square.certificate:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.rev_slider .tp-caption.signature, .signature, #hp-bottom-carousel h1 {
  font-family: "Amostely Signature";
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: inherit;
}

* {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  padding-left: 80px;
  font-family: "Montserrat", sans-serif;
}
body #menu-overlay.active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #040404cc;
  z-index: 2;
}
body footer {
  background-color: black;
  color: white;
}
body footer .contacts {
  background-color: #daaf71;
  color: black;
}
body footer .contacts .lnr {
  color: white;
  font-size: 1.5em;
  margin-right: 0.3em;
  vertical-align: text-bottom;
}
body footer .disclaimer {
  display: block;
  width: 90%;
  margin: auto;
  font-size: 0.6em;
  color: #505050;
  margin-top: 1em;
}
body header {
  position: relative;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  color: white;
  z-index: 999;
}
body header .collapsed {
  background-color: black;
  width: 80px;
  position: relative;
  background-position: center center;
  background-size: 50%;
  background-repeat: no-repeat;
  background-image: url(../images/lettering.svg);
}
body header .collapsed .logotype {
  background-color: #daaf71;
  background-position: center center;
  background-size: contain;
  border: 5px solid #daaf71;
  background-repeat: no-repeat;
  background-image: url(../images/logotype.svg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
body header .collapsed .logotype:before {
  content: "";
  padding-bottom: 100%;
  display: block;
}
body header .collapsed .menu-icon {
  border-top: 1px solid #505050;
  background-position: center center;
  background-size: 35%;
  background-repeat: no-repeat;
  background-image: url(../images/bars.svg);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
body header .collapsed .menu-icon:before {
  content: "";
  padding-bottom: 100%;
  display: block;
}
body header nav {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  margin-left: 80px;
  background-color: black;
  transform: translateX(-100%);
  z-index: -1;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
}
body header nav.active {
  transform: translateX(0%);
  opacity: 1;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
}
body header nav a:hover {
  color: #daaf71;
}
body header nav > ul {
  list-style: none;
  padding: 0;
  white-space: nowrap;
  margin: 0;
  text-align: left;
  font-size: 1.7em;
  font-weight: 600;
}
body header nav > ul > li {
  padding-bottom: 0.5em;
}
body header nav .langs {
  position: absolute;
  bottom: 0;
  left: 0;
}
body header nav .langs > a {
  text-transform: uppercase;
  font-size: 1.3em;
  padding: 10px;
}
body header nav .langs > a.active {
  color: #daaf71;
}

.bg.light-grey {
  background-color: gainsboro;
}
.bg.black {
  background-color: black;
  color: white;
}
.bg.bg-1 {
  background-image: url(../images/bg_1.svg);
  background-position: center right;
  background-size: contain;
}

.text-with-icon {
  color: #505050;
}
.text-with-icon h6 {
  font-weight: 600;
  color: black;
}
.text-with-icon .icon {
  width: 3em;
  display: inline-block;
  margin-bottom: 1em;
}
.text-with-icon .icon:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

#hp-bottom-carousel h1 {
  color: #daaf71;
  font-size: 6em;
  margin: 0 0 0.7em 0;
}
#hp-bottom-carousel p {
  text-align: justify;
}

.squared-navigation > span {
  border: 1px solid #505050;
  width: 3em;
  cursor: pointer;
  position: relative;
}
.squared-navigation > span:after {
  font-family: "Linearicons-Free";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.squared-navigation > span.prev:after {
  content: "";
}
.squared-navigation > span.next:after {
  content: "";
}
.squared-navigation > span:first-child {
  border-right-color: transparent;
  margin-right: -1px;
}
.squared-navigation > span:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.signature {
  color: #daaf71;
}
.signature.big {
  font-size: 6em;
}

.hp_section_1 img {
  transform: translateY(5em);
}

.rev_slider .tp-bullets {
  left: 98% !important;
}
.rev_slider .tp-bullets .tp-bullet {
  background-color: rgba(80, 80, 80, 0.6);
  height: 0.25em;
  width: 3em;
  border-radius: 1em;
}
.rev_slider .tp-bullets .tp-bullet.selected {
  background-color: #daaf71;
}
.rev_slider .tp-caption.signature {
  color: white;
}
.rev_slider .tp-caption.text {
  color: white;
}
.rev_slider .tp-caption.text span {
  color: #daaf71;
  font-weight: 600;
  text-shadow: 0px 0px 10px rgba(33, 33, 33, 0.7);
}
.rev_slider .tp-caption.section {
  color: white;
}
.rev_slider .tp-caption.section:before {
  content: "";
  width: 2em;
  height: 0.1em;
  border-radius: 1em;
  background-color: #daaf71;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1em;
}
.rev_slider .tp-caption ul {
  color: white;
}
.rev_slider .tp-caption ul.list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.4em;
}
.rev_slider .tp-caption ul.list > li {
  display: inline-block;
}
.rev_slider .tp-caption ul.list > li:first-child:before {
  content: "";
  display: none;
}
.rev_slider .tp-caption ul.list > li:before {
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  background-color: #daaf71;
  border-radius: 100%;
  margin: 0 0.5em;
  transform: translateY(-0.1em);
}

button {
  border-width: 2px;
  font-size: 0.9em;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
button:hover {
  transition: all 0.2s ease-in-out;
}
button.b-w {
  border-color: white;
  background-color: black;
  color: white;
}
button.b-w:hover {
  background-color: #1a1a1a;
}
button.w-b {
  border-color: #505050;
  background-color: white;
  color: black;
}
button.w-b:hover {
  background-color: #e6e6e6;
}
button.b-g {
  border-color: #daaf71;
  background-color: black;
  color: #daaf71;
}
button.b-g:hover {
  background-color: #1a1a1a;
}

.page-header {
  background-position: center center;
  background-size: cover;
  background-color: transparent;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: white;
  position: relative;
}
.page-header:before {
  content: "";
  display: inline;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.page-header ul {
  font-family: "Montserrat", sans-serif;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.2em;
  font-weight: 100;
}
.page-header ul > li {
  display: inline-block;
}
.page-header ul > li:first-child:before {
  content: "";
  display: none;
}
.page-header ul > li:before {
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  background-color: #daaf71;
  border-radius: 100%;
  margin: 0 0.5em;
  transform: translateY(-0.1em);
}
.page-header h1 {
  font-family: "Amostely Signature";
  font-size: 5em;
}
.page-header small {
  font-size: 0.2em;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0.7em;
}
.page-header small:before {
  content: "";
  height: 3px;
  border-radius: 2em;
  display: inline-block;
  background-color: #daaf71;
  width: 2em;
  vertical-align: middle;
  margin-right: 0.5em;
}

.gold-circle {
  background-position: bottom right;
  background-size: contain;
  background-color: white;
  background-attachment: normal;
  background-repeat: no-repeat;
  background-image: url("../images/gold-circle-bg.svg");
}

.grey-shot {
  background-position: top left;
  background-size: contain;
  background-color: black;
  background-attachment: normal;
  background-repeat: no-repeat;
  background-image: url("../images/grey-shot-bg.svg");
  color: white;
}
.grey-shot .title.signature {
  font-size: 5em;
  color: white;
}
.grey-shot .title.classic {
  font-weight: 100;
}
.grey-shot .title.classic strong {
  color: #daaf71;
  font-weight: 700;
}

.grey-shot-light {
  background-position: top left;
  background-size: contain;
  background-color: white;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-image: url("../images/grey-shot-bg.svg");
}

.grey-circle {
  background-position: bottom right;
  background-size: contain;
  background-color: black;
  background-attachment: normal;
  background-repeat: no-repeat;
  background-image: url("../images/grey-circle-bg.svg");
  color: white;
}
.grey-circle .title.signature {
  font-size: 5em;
  color: #daaf71;
}
.grey-circle .title.classic {
  font-weight: 100;
}
.grey-circle .title.classic strong {
  color: #daaf71;
  font-weight: 700;
}

.title.signature {
  font-size: 5em;
  color: white;
}
.title.classic {
  font-weight: 100;
}
.title.classic strong {
  color: #daaf71;
  font-weight: 700;
}

ul.lined {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 2.5em;
}
ul.lined > li {
  margin-bottom: 0.5em;
  text-align: justify;
}
ul.lined > li:before {
  content: "";
  display: inline-block;
  width: 2em;
  height: 2px;
  border-radius: 2em;
  background-color: #daaf71;
  vertical-align: middle;
  margin-left: -2.7em;
  margin-right: 0.7em;
}

.squared:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

form {
  text-align: left;
}
form label {
  text-transform: uppercase;
  font-size: 0.7em;
  display: block;
  color: #daaf71;
}
form .thx {
  font-size: 0.9em;
}
form .error {
  color: red;
  text-transform: uppercase;
  font-size: 0.8em;
}
form input:not([type=checkbox]), form textarea {
  background-color: transparent;
  border: 0;
  display: block;
  width: 100%;
  margin-bottom: 2em;
  resize: none;
  font-size: 0.9em;
  padding: 5px;
  border-bottom: 1px solid black;
  color: black;
}
form input:not([type=checkbox]):focus, form textarea:focus {
  outline: none;
}
form button[type=submit] {
  background-color: #daaf71;
  border-color: black;
  color: black;
}
form button[type=submit]:focus {
  outline: none;
}
form button[type=submit][disabled] {
  opacity: 0.4;
}
form .checkbox-label {
  background-color: transparent;
  display: inline-block;
  width: 1em;
  position: relative;
  cursor: pointer;
}
form .checkbox-label:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}
form .checkbox-label > input[type=checkbox] {
  display: none;
}
form .checkbox-label > label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: transparent;
  margin: 0;
  border: 2px solid #daaf71;
  cursor: pointer;
}
form .checkbox-label > label.active {
  background-position: center center;
  background-size: 60%;
  background-color: transparent;
  background-attachment: unset;
  background-repeat: no-repeat;
  background-image: url("../images/icons/checkbox-check.svg");
}

.big-modal {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.big-modal.bg-blk {
  background-color: black;
}
.big-modal.bg-blk .modal-content {
  background-color: transparent;
  color: white;
}
.big-modal.bg-blk form label {
  color: #daaf71;
}
.big-modal.bg-blk form button[type=submit] {
  background-color: black;
  border-color: #daaf71;
  color: #daaf71;
}
.big-modal.bg-blk form .thx > span {
  color: #daaf71;
}
.big-modal.bg-blk form input:not([type=checkbox]), .big-modal.bg-blk form textarea {
  border-bottom: 1px solid white;
  color: white;
}
.big-modal .popup-close {
  width: 4em;
  background-position: center center;
  background-size: contain;
  background-color: transparent;
  background-attachment: unset;
  background-repeat: no-repeat;
  background-image: url("../images/icons/close.svg");
  position: fixed;
  top: 2em;
  right: 2em;
  border: none;
}
.big-modal .popup-close:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.image-carousel.square-img .item {
  background-position: center center;
  background-size: cover;
  background-color: transparent;
  background-attachment: unset;
  background-repeat: no-repeat;
}
.image-carousel.square-img .item:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.gmap {
  background-color: gainsboro;
  height: 25em;
  position: relative;
}

.reference {
  background-position: center center;
  background-size: contain;
  background-color: transparent;
  background-attachment: unset;
  background-repeat: no-repeat;
}

.fade-enter {
  opacity: 0.01;
  transform: translateY(20px);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transform: translateY(20px);
  transition: all 300ms ease-in;
}

.fade-appear {
  opacity: 0.01;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.filter-bar button {
  margin: 0 0.3em;
}
.filter-bar button:focus {
  outline: none;
  background-color: #daaf71;
  color: black;
}

.static-image {
  position: relative;
}
.static-image.hover:hover > div {
  opacity: 1;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}
.static-image.hover > div {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

